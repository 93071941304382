import React from "react";
import { graphql } from "gatsby";

import PublicSymbolPage from "../../../components/public-symbol-page";

export const query = graphql`
  query ContextualSymbolsPageQuery {
    page: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      descriptionFrench: publicSymbolsContextualDescription_french
      description: publicSymbolsContextualDescription
    }
    symbols: allSanitySymbol(
      filter: { category: { eq: "contextual" }, hidden: { eq: false } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title_french
        title
        category
        slug: slugFr {
          current
        }
        mainImage {
          ...SanityImage
          alt_french
          alt
          caption_french
          caption
        }
        _rawDescriptionFrench(resolveReferences: { maxDepth: 5 })
        _rawDescription(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

const ContextualSymbolsPage = ({ data = {} }) => (
  <PublicSymbolPage title="Haine contextuelle" data={data} isFrench={true} />
);

export default ContextualSymbolsPage;
